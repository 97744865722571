<template>
	<div id="join">
		<banner :picS="bannerS" />
		<div class="joinBox">
			<titleOne id="join_1" :info="lang.title.text13" en='Join Advantage' :title="lang.title.text12"></titleOne>
		</div>
		<div class="joinList">
			<ul class="widthBox acea-row">
				<li v-for="item in joinList">
					<img :src="item.img" alt="" class="pic">
					<h4>{{item.title}}</h4>
					<div class="txt">
						<p v-for="i in item.content">{{i}}</p>
					</div>
				</li>
			</ul>
		</div>
		<div class="process">
			<titleOne id="join_2" en='Joining process' :title="lang.title.text14"></titleOne>
			<div class="box widthBox">
				<img :src="joining.img" alt="" class="hidden-xs-only pic">
				<img :src="joining.img_h5" alt="" class="hidden-sm-and-up pic">
				
				<!-- <ul class="acea-row row-between">
					<li>
						<span class="iconfont icon-liuyan acea-row row-center-wrapper"></span>
						<p>前期咨询<br/>洽谈、考察</p>
					</li>
					<li>
						<span class="iconfont icon-liuyan acea-row row-center-wrapper"></span>
						<p>双方签约<br/>购买加盟套餐</p>
					</li>
					<li>
						<span class="iconfont icon-liuyan acea-row row-center-wrapper"></span>
						<p>提供全面培训<br/>指导销售服务</p>
					</li>
					<li>
						<span class="iconfont icon-liuyan acea-row row-center-wrapper"></span>
						<p>一键分享<br/>开启您的财富事业</p>
					</li>
					<li>
						<span class="iconfont icon-liuyan acea-row row-center-wrapper"></span>
						<p>专属客服<br/>一对一快速解答</p>
					</li>
				</ul>
				<img src="@/assets/bg_05.png" alt="" class="pic">
				<div class="hot acea-row row-between">
					<span class="active">了解项目</span>
					<span>签署协议</span>
					<span>运营指导</span>
					<span>正式启动</span>
					<span>售后服务</span>
				</div> -->
			</div>
		</div>
		<div class="our">
			<titleOne id="join_3" en='Our Cooperation' :title="lang.title.text15"></titleOne>
			<div class="cont">
				<div class="widthBox">
					<el-row :gutter="20">
						<el-col :xs="24" :span="11">
							
							<div class="form">
								<label class="formItem acea-row row-middle">
									<span class="iconfont icon-jurassic_user"></span>
									<input :placeholder="lang.public.text02" v-model="formData.name" type="text">
								</label>
								<label class="formItem acea-row row-middle">
									<span class="iconfont icon-dianhuatianchong"></span>
									<input :placeholder="lang.public.text03" v-model="formData.mobile" type="text">
								</label>
								<label class="formItem acea-row row-middle">
									<span class="iconfont icon-ditu-dibiao"></span>
									<input :placeholder="lang.public.text04" v-model="formData.address" type="text">
								</label>
								<button class="hidden-xs-only" @click="joinSubmit">{{lang.public.submit}}</button>
							</div>
							
						</el-col>
						<el-col :xs="24" :span="13">
							
							<div class="right">
								<p>
									{{joinsetting.text1}}</br>
									{{joinsetting.text2}}
								</p>
								<p>
									{{lang.public.text17}}：</br>
									<span>{{joinsetting.merchants_number}}</span>
								</p>
								<p>
									{{joinsetting.text3}}
								</p>
							</div>
							
						</el-col>
						<el-col class="hidden-sm-and-up" style="text-align: center;" :xs="24">
							<button class="formSubmit" @click="joinSubmit">{{lang.public.submit}}</button>
						</el-col>
					</el-row>
					
					
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import banner from '@/components/banner.vue'
	import titleOne from '@/components/titleOne.vue'
	export default {
		name: 'case',
		components:{
			banner,titleOne
		},
		computed: {
			bannerS() {
				return this.$store.state.bannerS
			},
			common() {
				return this.$store.state.common
			},
			lang() {
				return this.$store.state.lang
			},
		},
		data:function() {
			return {
				joinList:'',
				joining:[],
				joinsetting:{},
				formData:{
					name:'',
					mobile:'',
					address:''
				}
			};
		},
		created() {
			this.$api.get('home/index/joinAdvantage',{}).then(res=>{
				this.joinList = res.data.list
			})
			this.$api.get('home/index/joiningProcess',{}).then(res=>{
				this.joining = res.data.list
			})
			this.$api.get('home/index/joinsetting',{}).then(res=>{
				this.joinsetting = res.data
			})
		},
		mounted() {
			this.$nextTick(function(){
				if(this.$route.query.to != undefined || this.$route.query.to != null){
					setTimeout(()=>{
						document.getElementById(this.$route.query.to).scrollIntoView()
					},500)
				}else{
					document.body.scrollIntoView()
				}
			})
		},
		methods:{
			joinSubmit(){
				let reg = /^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,7,8]|8[0-9]|9[1,8,9])\d{8}$/;
				let lang = this.lang.public
				
				if(!this.formData.name||!this.formData.mobile||!this.formData.address){
					this.$message.error(lang.text06);
					return
				}
				if(!reg.test(this.formData.mobile)){
					this.$message.error(lang.text07);
					return
				}
				this.$confirm(lang.text18,lang.text09, {
				  confirmButtonText: lang.text10,
				  cancelButtonText:lang.close,
				  type: 'info'
				}).then(() => {
				  this.$api.post('/home/submits/joinMessage',this.formData).then(res=>{
					  this.$message({
						message:res.msg,
						type: 'success'
					  });
					  this.formData.name = ''
					  this.formData.mobile = ''
					  this.formData.address = ''
				  })
				})
			}
		}
	}
</script>

<style lang="scss">
	#join{
		.joinBox{
			padding:60px 0;
		}
		.joinList{
			background:rgba($color: #1D2A55, $alpha: 0.05);
			padding:80px 0 30px;
			li{
				width:calc(100% / 3 - 30px);
				background:#FFF;
				box-shadow: 0px 5px 19px 1px rgba(29, 42, 85, 0.2);
				margin-right:45px;
				padding-bottom:30px;
				margin-bottom:50px;
				text-align: center;
				&:nth-child(3n){
					margin-right:0;
				}
				.pic{
					width:100%;
				}
				h4{
					font-size:32px;
					padding:30px 0;
					margin:0 30px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					color:#1D2A55;
					position: relative;
					&::after{
						content:'';display: block;width:80px;height:4px;
						background:#1D2A55;
						position: absolute;
						bottom:0;
						left:50%;
						transform: translate(-50%,0);
					}
				}
				.txt{
					margin-top:30px;
					padding:0 30px;
					p{
						color:rgba($color: #1D2A55, $alpha: 0.5);
						font-size:24px;
					}
				}
			}
		}
		.process{
			padding:80px 0 0 0;
			.box{
				padding:60px 0;
				ul{
					margin-bottom:50px;
					li{
						background:#FFF;
						width:calc(100% / 5 - 20px);
						box-shadow: 0px 5px 19px 1px rgba(29, 42, 85, 0.2);
						text-align: center;
						padding:50px 0 30px;
						height:300px;
						box-sizing: border-box;
						position: relative;
						&:nth-child(2n){
							transform: translate(0,90px);
						}
						&::after{
							content:'';
							position: absolute;
							z-index: 1;
							left:50%;
							bottom:-20px;
							transform: translate(-50%,0);
							border-top:10px solid #FFF;
							border-left:10px solid transparent;
							border-right:10px solid transparent;
							border-bottom:10px solid transparent;
						}
						span{
							width:80px;
							height:80px;
							border-radius:50%;
							background:#1D2A55;
							color:#FFF;
							margin:0 auto;
							font-size:36px;
						}
						p{
							margin-top:30px;
							font-size:20px;
							line-height:36px;
						}
					}
				}
				.pic{
					width:100%;
				}
				.hot{
					padding:50px 0;
					span{
						width:calc(100% / 5 - 40px);
						background:#F3F4F6;
						color:#1D2A55;
						font-size:24px;
						margin-right:20px;
						line-height:60px;
						display: block;
						text-align: center;
						font-weight: bold;
						position: relative;	
						&::after{
							content:'';
							position: absolute;
							z-index: 1;
							right:-25px;
							top:50%;
							transform: translate(0,-50%);
							border-top:30px solid transparent;
							border-left:15px solid #F3F4F6;
							border-right:10px solid transparent;
							border-bottom:30px solid transparent;
						}
						&.active{
							background:#1D2A55;
							color:#FFF;
							&::after{
								border-left:15px solid #1D2A55;
							}
						}
						
					}
				}
			}
		}
		.our{
			padding-top:80px;
			border-top:1px solid rgba($color: #1D2A55, $alpha: 0.5);
			.cont{
				margin-top:50px;
				background:rgba($color: #1D2A55, $alpha: 0.05);
				padding:80px 0;
				.form{
					display: block;
					.formItem{
						margin-top:40px;
						padding:0px 30px;
						height:64px;
						box-sizing: border-box;
						border:1px solid rgba($color: #1D2A55, $alpha: 0.6);
						&:first-child{
							margin-top:0;
						}
					}
					span{
						font-size:36px;
						vertical-align: middle;
						width:36px;
						margin-right:14px;
						display: inline-block;
						color:rgba($color: #1D2A55, $alpha: 0.6);
					}
					.icon{
						width:36px;
						max-height:36px;
						margin-right:14px;
						vertical-align: middle;
					}
					input{
						width:calc(100% - 60px);
						border:0;background:none;
						font-size:18px;
						outline: 0;
						height:36px;
						color:rgba($color: #1D2A55, $alpha: 0.6);
						vertical-align: middle;
					}
					button{
						width: 240px;
						height: 64px;
						background: #1D2A55;
						text-align: center;
						color:#FFF;
						font-size:20px;
						margin-top:40px;
					}
				}
				.right{
					p{
						font-size:20px;
						line-height:36px;
						color:#1D2A55;
						margin-top:40px;
						&:first-child{
							margin-top:0;
						}
						span{
							font-size:24px;
						}
					}
				}
			}
		}
	}
	
	@media screen and(max-width:768px) {
		#join{
			.joinBox{
				padding:20px 0 30px;
			}
			.joinList{
				padding:40px 0 20px;
				li{
					width:calc(50% - 5px);
					margin-right:10px;
					padding-bottom:10px;
					margin-bottom:20px;
					&:nth-child(3n) {
					    margin-right: auto;
					}
					&:nth-child(even){
						margin-right:0;
					}
					
					h4{
						font-size:16px;
						padding:10px 0;
						margin:0 10px;
						line-height:20px;
						&::after {
							width: 40px;
							height: 2px;
						}
					}
					.txt{
						margin-top:10px;
						padding:0 10px;
						p{
							font-size:12px;
						}
					}
				}
			}
			
			.process{
				border-top: 1px solid rgba(29, 42, 85, 0.5);
				padding:20px 0 0 0;
				.box{
					padding:20px 0 0;
				}
			}
			.our{
				border-top:0;
				padding-top:20px;
				.cont{
					background:none;
					padding:0;
					margin-top:20px;
					.form{
						.formItem{
							height:40px;
							padding:0 10px;
							margin-top:20px;
							span{
								font-size:18px;
								width:18px;
								margin-right:5px;
							}
							input{
								font-size:14px;
							}
						}
					}
					
					.right{
						padding:20px 0;
						text-align: center;
						p{
							font-size:14px;
							line-height:20px;
							margin-top:10px;
							color:#898989;
							span{
								font-size:14px;
							}
						}
					}
					
					.formSubmit{
						width:150px;
						height:48px;
						background:#1D2A55;
						color:#FFF;
						text-align: center;
						font-size:16px;
						line-height:48px;
					}
				}
				
			}
		}
	}
</style>
